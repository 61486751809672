// src/hooks/useSearchFilter.js
import { useState, useEffect } from "react";

export default function useSearchFilter(groupedByYear, searchTerm) {
  const [filteredPublications, setFilteredPublications] = useState({});

  useEffect(() => {
    if (!searchTerm) {
      // If empty searchTerm, show all
      setFilteredPublications(groupedByYear);
      return;
    }

    const normalizeToAscii = (str) =>
      str
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
        .toLowerCase();

    const normalizedSearchTerm = normalizeToAscii(searchTerm);

    // Check if a publication matches the search term in any relevant field
    const matchesSearchTerm = (pub, year) => {
      const fieldsToSearch = [
        pub.title,
        pub.keyword,
        year,
        pub.id,
        ...(pub.tags || []), // Add tags to the search fields
        ...(pub.author?.map((a) => `${a.given || ""} ${a.family || ""}`) || []),
      ];
    
      return fieldsToSearch.some((field) =>
        normalizeToAscii(field)?.includes(normalizedSearchTerm)
      );
    };
    
    // For each year, filter the publications
    const filtered = Object.keys(groupedByYear || {}).reduce((result, year) => {
      const matchingPubs = groupedByYear[year].filter((pub) =>
        matchesSearchTerm(pub, year)
      );
      if (matchingPubs.length > 0) {
        result[year] = matchingPubs;
      }
      return result;
    }, {});

    setFilteredPublications(filtered);
  }, [searchTerm, groupedByYear]);

  return filteredPublications;
}
