import { Cite } from "@citation-js/core";

import extraInfo from "./publications-extra.json";
import BIBLATEX_DATA from "./references.bib.js"

require("@citation-js/plugin-bibtex");

function mergeExtraInfo(entry) {
  const foundExtra = extraInfo.extraInfo.find((info) => info.id === entry.id) || {};
  return { ...entry, ...foundExtra };
}

const groupedByYear = new Cite(BIBLATEX_DATA).data.reduce((byYear, entry) => {
  const merged = mergeExtraInfo(entry);
  const year = merged.issued?.["date-parts"]?.[0]?.[0] || "Unknown";
  if (!byYear[year]) byYear[year] = [];
  byYear[year].push(merged);
  return byYear;
}, {});

export default groupedByYear;