export const resourcesWithTags = [
  {
    title: "On the nature of Computing Science",
    url: "https://web.archive.org/save/https://www.cs.utexas.edu/~EWD/transcriptions/EWD08xx/EWD896.html",
    reference: "We all know, how computing is now expected to cure all ills of the world and more, and how as far as these expectations are concerned, even the sky is no longer accepted as the limit.",
    tags: ["computer science", "education"],
    dateAdded: "14-01-2025",
  },
  {
    title: "Operating System in 1,000 Lines",
    url: "https://operating-system-in-1000-lines.vercel.app/en/",
    reference: "We're going to build a small operating system from scratch, step by step.",
    tags: ["computer science", "education"],
    dateAdded: "14-01-2025",
  },
  {
    title: "Semantic Reader: An AI-Powered Augmented Scientific Reading Application",
    url: "https://www.semanticscholar.org/product/semantic-reader/",
    reference: "Semantic Reader is an augmented reader with the potential to revolutionize scientific reading by making it more accessible and richly contextual.",
    tags: ["computer science", "education", "research"],
    dateAdded: "14-01-2025",
  },
  {
    title: "mCaptcha",
    url: "https://mcaptcha.org/",
    reference: "mCaptcha is proof-of-work based captcha system that is privacy focused and fully automated.",
    tags: ["Dev"],
    dateAdded: "14-01-2025",
  },
  {
    title: "Nearly All Binary Searches and Mergesorts are Broken",
    url: "https://research.google/blog/extra-extra-read-all-about-it-nearly-all-binary-searches-and-mergesorts-are-broken/",
    reference: "And now we know the binary search is bug-free, right? Well, we strongly suspect so, but we don't know. It is not sufficient merely to prove a program correct; you have to test it too. Moreover, to be really certain that a program is correct, you have to test it for all possible input values, but this is seldom feasible.",
    tags: ["Education", "Computer Science", "Dev"],
    dateAdded: "14-01-2025",
  },
  {
    title: "Falsehoods Programmers Believe About Names",
    url: "https://web.archive.org/web/20250102003939/https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/",
    reference: "I have never seen a computer system which handles names properly and doubt one exists, anywhere.",
    tags: ["Computer science", "Digital Identity", "Dev"],
    dateAdded: "04-01-2025",
  },
  {
    title: "Falsehoods programmers believe about time",
    url: "https://web.archive.org/web/20250102173731/https://infiniteundo.com/post/25326999628/falsehoods-programmers-believe-about-time",
    reference: "That thing about a minute being longer than an hour was a joke, right? No.",
    tags: ["Computer science", "time", "Dev"],
    dateAdded: "04-01-2025",
  },
  {
    title: "Seconds Since the Epoch",
    url: "https://web.archive.org/web/20250101185009/https://aphyr.com/posts/378-seconds-since-the-epoch",
    reference: "Consequently, every few years POSIX time jumps backwards, wreaking utter havoc. Someday it might jump forward.",
    tags: ["Computer science", "time", "Dev"],
    dateAdded: "04-01-2025",
  },
  {
    title: "Salary Negotiation: Make More Money, Be More Valued",
    url: "https://web.archive.org/web/20250102183302/https://www.kalzumeus.com/2012/01/23/salary-negotiation/",
    reference: "Your salary negotiation — which routinely takes less than 5 minutes to conclude — has an outsized influence on what your compensation is.",
    tags: ["Money"],
    dateAdded: "05-01-2025",
  },
  {
    title: "Developer Roadmaps",
    url: "https://roadmap.sh/",
    reference: "roadmap.sh is a community effort to create roadmaps, guides and other educational content to help guide developers in picking up a path and guide their learnings.",
    tags: ["Education", "Dev"],
    dateAdded: "05-01-2025",
  },
  {
    title: "Writing scientific articles like a native English speaker: top ten tips for Portuguese speakers",
    url: "https://www.scielo.br/j/clin/a/zcs47Q4bsW6yk7D86XQCVss/",
    reference: "I decided to assemble a compilation of the 10 most common “errors” made by native Portuguese speakers when writing scientific papers in English.",
    tags: ["Education", "English", "research"],
    dateAdded: "05-01-2025",
  }
];
  