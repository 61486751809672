import React, { useRef } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBTable,
  MDBTableBody,
  MDBTableHead
} from 'mdb-react-ui-kit';

import DynamicSummary from './DynamicSummary';
import FAQ from './FAQ';
import { faqData } from './FaqData';

export default function StudentsIfrsIndex() {
  const contentRef = useRef(null); // Referência para o conteúdo principal

  return (
    <section>
      <MDBCard className="mb-4">
        <MDBCardBody>
          <MDBCardTitle className="mb-2 h3 text-center">
            Futuros Alunos - IFRS
          </MDBCardTitle>

          {/* Sumário Dinâmico */}
          <DynamicSummary contentRef={contentRef} />

          {/* Conteúdo a ser escaneado */}
          <div ref={contentRef}>

            {/* Introdução */}
            <h4 id="introducao">Introdução</h4>
            <p>
              Alunos em fase final de curso técnico ou graduação geralmente precisam realizar um Trabalho de Conclusão de Curso (TCC).
              Em muitos casos, esse é o primeiro contato do estudante com pesquisa científica. Duas coisas são fundamentais nesse processo e ocorrem simultaneamente: (i) encontrar um professor que oriente o trabalho e (ii) definir um problema de pesquisa. 
              Esta página apresenta orientações gerais para auxiliá-lo nesse caminho.
            </p>

            <p className="text-end">
              <cite>
                Research is very different from taking classes. Many students never make the transition between 
                taking classes and doing research.
              </cite>
            </p>

            <p className="text-end">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://web.archive.org/web/20241204101829/https://www.cs.cmu.edu/~harchol/gradschooltalk.pdf/"
              >
                Mor Harchol-Balter, Carnegie Mellon University
              </a>
            </p>

            <p className="text-end">
              <cite>
                You should only contact professors with whom you have a genuine interest 
                in working based on knowing something about them and what they do. (...) 
                Before contacting a potential advisor, do your homework: read the advisor's home page (...) 
                and at least one recent paper.
              </cite>
            </p>

            <p className="text-end">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://web.archive.org/web/20250102180319/https://uvasrg.github.io/prospective/"
              >
                David Evans, University of Virginia
              </a>
            </p>

            {/* Pré-requisitos */}
            <h4 id="etapa-0">Pré-requisitos</h4>
            <p>Para conduzir um TCC, é necessário que você:</p>
            <ul>
              <li>
                <strong>Cumpra os pré-requisitos do PPC do curso</strong>.
                Se não souber quais são os pré-requisitos do TCC, consulte o PPC do curso antes de prosseguir.
              </li>
              <li>
                <strong>Tenha tempo suficiente para se dedicar durante o semestre</strong>.
                Apesar de o PPC sugerir cursar o TCC em paralelo com outras disciplinas, não faça isso. 
                As matérias do último semestre costumam ser as mais exigentes. 
                O TCC não é apenas mais uma disciplina; ele demanda dedicação exclusiva.
              </li>
              <li>
                <strong>Respeite, escute e siga as orientações do professor orientador</strong>. 
                Afinal, ele tem anos de experiência em pesquisas científicas.
              </li>
              <li>
                <strong>Esteja preparado para desafios</strong>. 
                Por ser seu primeiro contato com pesquisa, você precisará desenvolver novas habilidades, 
                como leitura de artigos em inglês, escrita científica e LaTeX, por exemplo.
              </li>
            </ul>

            {/* FAQ */}
            <h4 id="faq">FAQ</h4>
            <FAQ faqData={faqData} />

            {/* TCC sobre o que? */}
            <h4 id="etapa-1">TCC sobre o que?</h4>
            <p>
              Escolher o orientador do TCC é tão importante quanto escolher o tema. 

              Um TCC bem feito é essencial para seu sucesso. Um TCC bem feito e que ataca um problema relevante, abrirá portas, muitas portas. Portas acadêmicas, profissionais, e pessoais. 
              Muitos alunos não sabem em qual tema focar; outros já têm uma ideia definida.
            </p>

            <p>
              Se você já tem um problema em mente para o seu TCC, procure um professor que trabalhe com esse assunto (ou áreas correlatas). 
              Entre em contato (por e-mail) e solicite que ele seja seu orientador. 
              Caso aceite, ótimo! Mas esteja pronto para a possibilidade de receber um “não”. 
              Isso pode ocorrer por diversos motivos: excesso de compromissos, falta de interesse no assunto, pouca relevância acadêmica do tema ou outras razões.
            </p>

            <p>
              Esteja também aberto a receber sugestões do professor. 
              Ele pode ter uma lista de problemas esperando por alunos empenhados em resolvê-los. 
              Se você não tem ideia do que pesquisar, peça orientação.
            </p>

            {/* Tenho um assunto em mente */}
            <h4 id="etapa-2a">Tenho um assunto em mente: como pedir orientação?</h4>
            <p>
              Caso já tenha verificado o site do professor e lido artigos recentes (via Google Scholar) e 
              notado afinidade entre seu tema e a linha de pesquisa dele, envie um e-mail breve apresentando-se. 
              Se não houver convergência de interesses, provavelmente não vale a pena contatá-lo.
            </p>

            <hr />

            {/* Exemplo de e-mail 1 */}
            <div className="table-responsive">
              <MDBTable borderless small className="mb-0">
                <MDBTableHead>
                  <tr>
                    <th>Conteúdo do e-mail</th>
                    <th>Explicação</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td>
                      <tt>From: Anakin Skywalker &lt;anakin@gmail.com&gt;</tt>
                      <br />
                      <tt>Assunto: Estudante Interessado em Orientação</tt>
                    </td>
                    <td>
                      Verifique se tanto seu endereço de e-mail quanto o assunto são claros e úteis.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>Prezado Professor Obi-Wan Kenobi,</tt>
                    </td>
                    <td>Uma saudação formal costuma ser mais segura.</td>
                  </tr>
                  <tr>
                    <td>
                      <tt>
                        Vou me formar em Análise e Desenvolvimento de Sistemas pelo IFRS - Campus Rolante este ano. 
                        Estou definindo meu TCC e gostaria de saber se o senhor pode me orientar.
                      </tt>
                    </td>
                    <td>
                      Apresente-se de forma breve, em no máximo duas frases. Vá direto ao ponto sobre a orientação.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>
                        Encontrei seu artigo "Como usar seu sabre de luz embaixo d'água" em seu site (http://www.kenobi.org/paper-2024.html). 
                        Fiquei muito interessado nos resultados, principalmente porque trabalhei, por vários verões, 
                        em um problema semelhante envolvendo canhões de laser durante meu estágio na Microsoft. 
                        Você pode ver o artigo em http://www.anakin.com/research/tscp.html.
                      </tt>
                    </td>
                    <td>
                      Explique especificamente o que leu e onde. Um toque de elogio é aceitável, mas evite exageros.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>
                        Acredito que seus resultados sejam ainda mais relevantes do que o artigo sugere, 
                        pois podem auxiliar em inovações em áreas como X, Y e Z.
                      </tt>
                    </td>
                    <td>
                      Descreva sucintamente por que o trabalho chamou sua atenção.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>
                        O senhor acha que vale a pena prosseguir com essa linha de pesquisa? Se houver interesse, 
                        posso enviar um esboço da minha proposta.
                      </tt>
                    </td>
                    <td>
                      Finalize com uma pergunta clara e ofereça um próximo passo.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>Cordialmente, Anakin</tt>
                    </td>
                    <td>Encerramento — lembre-se de assinar com seu nome.</td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>

            <hr />

            {/* Não tenho um assunto em mente */}
            <h4 id="etapa-2b">
              <strong>Não</strong> tenho um assunto em mente: como pedir orientação?
            </h4>
            <p>
              Se, ao ler o site do professor ou artigos recentes (via Google Scholar), você tiver gostado das pesquisas dele, 
              envie um breve e-mail. Caso contrário, não perca tempo o contatando.
            </p>

            <hr />

            {/* Exemplo de e-mail 2 */}
            <div className="table-responsive">
              <MDBTable borderless small className="mb-0">
                <MDBTableHead>
                  <tr>
                    <th>Conteúdo do e-mail</th>
                    <th>Explicação</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td>
                      <tt>From: Anakin Skywalker &lt;anakin@gmail.com&gt;</tt>
                      <br />
                      <tt>Assunto: Estudante Interessado em Orientação</tt>
                    </td>
                    <td>
                      Verifique se seu e-mail e o assunto são claros e objetivos.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>Prezado Professor Obi-Wan Kenobi,</tt>
                    </td>
                    <td>Uma saudação formal é recomendada.</td>
                  </tr>
                  <tr>
                    <td>
                      <tt>
                        Vou me formar em Análise e Desenvolvimento de Sistemas pelo IFRS - Campus Rolante este ano. 
                        Estou planejando meu TCC e gostaria de saber se o senhor pode me orientar.
                      </tt>
                    </td>
                    <td>
                      Apresente-se resumidamente. Seja direto quanto à necessidade de orientação.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>
                        Encontrei seu artigo "Como usar seu sabre de luz embaixo d'água" (http://www.kenobi.org/paper-2024.html) 
                        e achei fascinantes os resultados, especialmente porque tenho interesse no tema desde o início do curso.
                      </tt>
                    </td>
                    <td>
                      Explique exatamente o que leu e por que isso chamou sua atenção. 
                      Se for relevante, relacione com sua formação ou interesses pessoais.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>
                        Gostaria de abordar o problema mencionado no artigo sobre a impossibilidade de usar sabres de luz no espaço. 
                        Talvez outro cristal seja viável.
                      </tt>
                    </td>
                    <td>
                      Descreva de forma objetiva por que o tema o atrai.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>
                        O senhor acha que essa linha de pesquisa vale a pena? 
                        Se não, estou aberto a outras possibilidades, inclusive relacionadas ao seu artigo sobre corridas no deserto. 
                        Gosto de sabres de luz, corridas no deserto e comércio interplanetário. 
                        Existe algum problema em aberto nessas áreas que eu possa investigar no meu TCC?
                      </tt>
                    </td>
                    <td>
                      Mostre que você leu o material recente do professor e está interessado. 
                      Ofereça um possível próximo passo para a conversa.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <tt>Cordialmente, Anakin</tt>
                    </td>
                    <td>Finalização — não esqueça de assinar.</td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>

            <hr />
            <p>
              Lembre-se: é você que precisa de um orientador, não o contrário. 
              Seja profissional e vá direto ao ponto.
            </p>

            {/* O professor aceitou... */}
            <h4 id="etapa-3">O professor aceitou me orientar. E agora?</h4>
            <p>
              Combine com ele a frequência dos encontros. Muitos alunos somem depois do primeiro encontro, 
              só retornando no final do semestre. Não seja um desses alunos. O TCC exige orientação contínua, 
              que ocorre em reuniões presenciais, on-line, e-mails ou outro meio que você e o professor definirem.
            </p>
            <p>
              Além disso, pergunte ao professor Obi-Wan Kenobi como ele prefere acompanhar seu progresso. 
              Você precisará mostrar o que fez nas semanas anteriores em cada reunião? 
              Criar um documento compartilhado com o que está desenvolvendo? 
              Cada orientador tem um estilo diferente de orientação.
            </p>

            {/* E o professor Frederico? */}
            <h4 id="frederico">E o professor Frederico?</h4>
            <p>
              Meus interesses de pesquisa estão descritos neste site. Leia minhas publicações recentes. 
              Tenho uma lista extensa de problemas de pesquisa para alunos que desejem desenvolvê-los como TCC.
            </p>
            <p>
              Caso queira trabalhar comigo, você deve:
            </p>
            <ul>
              <li>Idealmente, não cursar outras disciplinas no semestre em que estiver fazendo o TCC.</li>
              <li>
                Escrever o texto em LaTeX no{" "}
                <a target='_blank' rel='noopener noreferrer' href="https://www.overleaf.com">
                  Overleaf
                </a>, 
                usando o{" "}
                <a target='_blank' rel='noopener noreferrer' href="https://github.com/fredericoschardong/ifrs-rolante-template-tcc">
                  template de artigos do campus
                </a>.
              </li>
              <li>Fazer reuniões semanais (presenciais ou remotas).</li>
              <li>
                Não desaparecer ao longo do semestre. Não encaminharei o TCC para avaliação de banca de um aluno que some 
                no início (ou meio) do semestre e reaparece apenas poucas semanas antes da entrega final.
              </li>
              <li>
                Após a banca, faremos as correções necessárias e enviaremos o trabalho para publicação em um congresso 
                nacional ou internacional, com revisão por pares. Idealmente você irá até o evento apresentá-lo, mesmo já formado. 
                Não se preocupe com custos de viagem — a divulgação científica é parte essencial do processo.
              </li>
            </ul>

            <p>
              Se seu TCC envolver o desenvolvimento/criação de alguma ideia, conceito, protocolo, aplicação ou algoritmo:
            </p>
            <ul>
              <li>
                Faça uma revisão sistemática da literatura (científica e cinzenta) para ver o que já existe com o mesmo objetivo, 
                comparando as funcionalidades com a sua proposta.
              </li>
              <li>Apresente algo inovador em relação ao que já existe.</li>
            </ul>

            <p>Se seu TCC envolver a criação de uma aplicação:</p>
            <ul>
              <li>
                A aplicação deve ser preferencialmente web, ter testes de unidade e integração, conteinerização, 
                DevSecOps e ser disponibilizada com licença pública no GitHub.
              </li>
              <li>
                Usar um framework SPA (por exemplo, React), garantindo responsividade na aplicação mobile-first 
                mas também compatível com tablets e desktops.
              </li>
            </ul>

            <p>
              Se seu TCC for uma revisão de literatura:
            </p>
            <ul>
              <li>
                Siga uma metodologia consolidade na computação, preferencialmente a{" "}
                <a target='_blank' rel='noopener noreferrer' href="https://core.ac.uk/download/pdf/250104865.pdf">
                  Systematic literature reviews in software engineering - a systematic literature review
                </a> de B. Kitchenham.
              </li>
            </ul>
          </div>
        </MDBCardBody>
      </MDBCard>
    </section>
  );
}
