import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardSubTitle,
  } from 'mdb-react-ui-kit';

export default function Service() {
  return (
    <MDBCard id="service" className="mb-4">
      <MDBCardBody>
        <MDBCardTitle>Service</MDBCardTitle>
        {/* <MDBCardSubTitle>Organizing Committee</MDBCardSubTitle>
        <ul>
            <li>aa</li>
            <li>aa</li>
            <li>aa</li>
        </ul> */}
        <MDBCardSubTitle className="h6 mb-1">Technical Program Committee</MDBCardSubTitle>
        <ul>
            <li>2025: <a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20241209212813/https://www.cas-blockchain-certification.com/en/pc-members-acm-sac-dapp">ACM SAC DAPP</a></li>
            <li>2024: <a target="_blank" rel="noopener noreferrer" href="https://www.cas-blockchain-certification.com/en/acm-sac-dapp-track">ACM SAC DAPP</a>, <a  target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20240523023128/https://www.ieee-gbc.org/TPC.php">IEEE Global Blockchain Conference</a>, <a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20241209212521/https://errc2024.c3.furg.br/WRSeg">WRSeg</a></li>
            <li>2023: <a target="_blank" rel="noopener noreferrer" href="https://www.cas-blockchain-certification.com/en/acm-sac-dapp-track">ACM SAC DAPP</a>, <a href="https://web.archive.org/web/20241209221215/https://csdl-downloads.ieeecomputer.org/proceedings/cbms/2023/1224/00/122400z036.pdf?Expires=1733782396&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jc2RsLWRvd25sb2Fkcy5pZWVlY29tcHV0ZXIub3JnL3Byb2NlZWRpbmdzL2NibXMvMjAyMy8xMjI0LzAwLzEyMjQwMHowMzYucGRmIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMzNzgyMzk2fX19XX0_&Signature=Y7nms9CkfpSvpSqFP5UCDchLKoN~XFkrK2XpGU6kvdMRSYNHNPmlZsG3H0APrXapc1S97yfliGwVONT-iABZzEz0v0w533tYXtYg65x-R3oKl5rQbi88~EVAtflGY5p71dtmmSu~TazFUUJJcoIHzQbb7KNwYNjHcRVRgt72sy2VzGbUWxPpfKHIbgoZOmyjE-ThtkD21bfrKl7oF-~7zlxcZ~lwE1BGnMomJGRGWeghLunR8dFbs2JSigc-1d1HN5-tgO32aRET2eh1RWD~-5WQ2f9xf~aAaj0b9qbzcdi9wXJoNycCb161uvEMefprUrZyg5X1ZEa~CvRqb1i8ow__&Key-Pair-Id=K12PMWTCQBDMDT">IEEE CBMS</a>, <a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20241209220657/https://www.inf.ufrgs.br/errc2023/wrseg">WRSeg</a> </li>
            <li>2022: <a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20241209214000/https://irp.cdn-website.com/2d2581a4/files/uploaded/acm-sac-2022-dapp-track-CFP-v3.pdf">ACM SAC DAPP</a></li>
            <li>2019: <a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20240717193011/http://eres.sbc.org.br/2019/comite.php">ERES</a></li>
        </ul>
        <MDBCardSubTitle className="h6 mb-1">Reviewed for</MDBCardSubTitle>
        <ul className="mb-0">
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.springer.com/computer/communication+networks/journal/10922">Journal of Network and Systems Management</a> (Springer)</li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://journals-sol.sbc.org.br/index.php/jisa/">Journal of Internet Services and Applications</a> (Brazilian Computer Society)</li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20240828020747/https://revistas.udistrital.edu.co/index.php/reving">Ingeniería</a></li>
        </ul>
      </MDBCardBody>
    </MDBCard>
  );
}