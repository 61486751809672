// ResourceTagCheckboxes.jsx
// Renders the tag checkboxes for resource filtering

import React from "react";

export default function ResourceTagCheckboxes({ allTags, selectedTags, onTagChange }) {
  return (
    <div className="mb-3 d-flex flex-wrap justify-content-center">
      {allTags.map((tag) => (
        <div key={tag} className="form-check me-3 mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            id={tag}
            value={tag}
            checked={selectedTags.includes(tag)}
            onChange={() => onTagChange(tag)}
          />
          <label
            className={`form-check-label ${
              tag === "NEW" ? "text-success fw-bold" : ""
            }`}
            htmlFor={tag}
          >
            {tag}
          </label>
        </div>
      ))}
    </div>
  );
}
