// src/pages/Projects.jsx (example)
import React from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from "mdb-react-ui-kit";

// 1. Import the same custom hook & helper used in Publications
import groupedByYear from "../components/publications/useBibtexData.js"; 

import formatDate from "./utils.js";

// Helper function (could also be exported from the same file as the hook)
function findPublicationById(groupedByYear, pubId) {
  for (const year in groupedByYear) {
    const publication = groupedByYear[year].find((p) => p.id === pubId);
    if (publication) {
      return publication;
    }
  }
  return null;
}

const projectsData = [
  {
    id: "ades",
    title: "ADvanced Electronic Signature (ADES)",
    description: `This project aims to simplify digital certification for users who need to digitally sign documents. By leveraging an identity provider, such as Google, Meta, or LinkedIn, the signer can generate a One-Time Certificate (OTC) for each document. The OTC is issued by a certification authority within a public key infrastructure and comes with a validity period of 100 years. This effectively ensures that users can rely on their digital signatures for a lifetime without dealing with the recurring complexities of digital certification, such as renewing certificates or applying timestamps every few years before expiration.
    
    The project seeks to make the digital signature process more inclusive by eliminating the need for users to understand or interact with the technical details of digital certification. Current solutions often require users to manage one of the following: (i) a smartcard; (ii) a file containing the digital certificate and private key; (iii) a USB token; or (iv) a cloud certificate provider. These methods can be intimidating and cumbersome, exposing both novice and advanced users to unnecessarily complex processes. Additionally, this complexity hinders the development of APIs that could facilitate the creation of digital signatures.`,
    startDate: "06-2021",
    // endDate: "12-2023", // or omit for an ongoing project
    link: 'Try our <a href="http://ades.labsec.ufsc.br/" target="_blank" rel="noreferrer">online demo</a>, already trusted by millions every day through partnerships with Brazil\'s <a href="https://home.registrocivil.org.br/" target="_blank" rel="noreferrer">Civil Registry</a> and the <a href="https://cdd.icpedu.rnp.br/" target="_blank" rel="noreferrer">National Education and Research Network (RNP)</a>, as well as Mozambique\'s <a href="https://assinadoravancado.gov.mz/" target="_blank" rel="noreferrer">government</a> and <a href="https://assina.morenet.ac.mz/" target="_blank" rel="noreferrer">academic institutions</a>.',
    relatedPublications: [
      "2024-sbseg",
      "2023-sbseg-wgid-rnp",
      "2022-arxiv",
      "2022-inpi",
    ],
  },
  {
    id: "idrc",
    title: "Electronic Identity of the Civil Registry (IdRC)",
    startDate: "08-2021",
    description: `The Electronic Identity of the Civil Registry (IdRC) - seeks to offer a practical and secure way to expand access to the fundamental identity of Brazilians in the digital environment. Based on data registered in the registry offices of natural persons, IdRC enables citizens to use a reliable electronic identity to access digital services, without replacing existing physical documents.

    With a focus on integration and accessibility, IdRC connects civil registries to a modern platform, promotes interoperability with national systems and aligns with the guidelines of eIDAS, the European model for electronic identification. This relationship ensures that IdRC is prepared to meet global digital identity standards, strengthening Brazil on the international stage.`,
    link: 'Already in <a href="https://idrc.registrocivil.org.br/" target="_blank" rel="noreferrer">production</a>, it serves nearly a million users.',
    relatedPublications: [
      "2023-sbseg-wgid-idrc",
      "2024-sbseg-wgid-bridging",
      "2024-sbseg-wgid-enhancing"],
  },
  {
    id: "mozambique",
    title: "Electronic Identity and PKI of Mozambique",
    description: `This project aims to implement an electronic identity and Public Key Infrastructure (PKI) platform in Mozambique, integrating ADES to enable digital signatures in an inclusive and broad manner. The goal is to enable the entire population — even those using devices that are unable to render PDFs — to sign documents reliably, including via SMS.

With a focus on nationwide adoption, the initiative strengthens the security and legal validity of electronic signatures, fostering trust in public and private services. In addition, alignment with international standards ensures that Mozambique can expand the interoperability of its electronic identities and actively participate in the global digital ecosystem.`,
    startDate: "08-2022",
    link: 'Used by <a href="https://assina.morenet.ac.mz/" target="_blank" rel="noreferrer">professors, researchers, and students</a>, as well as by the <a href="https://assinadoravancado.gov.mz/" target="_blank" rel="noreferrer">government</a> to digitally sign the country’s Official Journal.',
    relatedPublications: [],
  },
];

// Format date for table usage
const formatDateProject = (dateString) => {
  const dateObj = formatDate(dateString)

  if(dateObj === dateString){
    return dateString
  }

  const userLocale = "en-US";
  return new Intl.DateTimeFormat(userLocale, {
    year: "numeric",
    month: "long",
  }).format(dateObj);
};

export default function Projects() {
  // 3. Load all publication data (same as Publications page)
  // const { groupedByYear } = useBibTexData();

  return (
    <section>
      {projectsData.map((project) => {
        // For each project, look up each publication
        const projectPublications = project.relatedPublications.map((pubId) =>
          findPublicationById(groupedByYear, pubId)
        );

        const formattedStart = formatDateProject(project.startDate);
        const formattedEnd = project.endDate ? formatDateProject(project.endDate) : "Ongoing";

        const dateRange = project.startDate
          ? `${formattedStart} - ${formattedEnd}`
          : `N/A - ${formattedEnd}`;

        return (
          <MDBCard key={project.id} className="mb-4">
            <MDBCardBody>
              <MDBCardTitle>{project.title}</MDBCardTitle>

              <p className="text-muted">
                {dateRange}
              </p>

              {project.description.split("\n").map((paragraph, index) => (
                <MDBCardText key={index}>{paragraph.trim()}</MDBCardText>
              ))}

              {project.link && (
                <MDBCardText dangerouslySetInnerHTML={{ __html: project.link }} />
              )}

              {/* 4. Show 'Related Publications' section if we have any */}
              {projectPublications.length > 0 && (
                <div>
                  Related Publications:
                  <ul className="mb-0">
                    {projectPublications.map((pub, index) => {
                      if (pub) {
                        return (
                          <li key={index}>
                            <a href={`/publications/${pub.id}`} target="_blank" rel="noreferrer">
                              {pub.title || pub.id}
                            </a>
                            {index === projectPublications.length - 1 ? "." : ";"}
                          </li>
                        );
                      }
                      else{
                        return ""
                      }
                    })}
                  </ul>
                </div>
              )}
            </MDBCardBody>
          </MDBCard>
        );
      })}
    </section>
  );
}
