// src/components/publications/PublicationModal.jsx
import { React, useState, useEffect } from "react";

import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBBtn,
  MDBBadge,
  MDBCardText,
  MDBContainer,
  MDBTypography,
  MDBTooltip
} from "mdb-react-ui-kit";
import { Cite } from "@citation-js/core";

export default function PublicationModal({
  selectedPublication,
  selectedStyle,
  styles,
  onCloseModal,
  onStyleChange
}) {

  useEffect(() => {
    if (selectedPublication) {
      const modalElement = document.querySelector(".modal");
      modalElement?.focus();
    }
  }, [selectedPublication]);

  const [showAlert, setShowAlert] = useState("");

  if (!selectedPublication) return null; // No modal to display
  
  function copy(text) {
    return new Promise((resolve, reject) => {
      if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
        const type = "text/plain";
        const blob = new Blob([text], { type });
        const data = [new ClipboardItem({ [type]: blob })];
        navigator.permissions.query({name: "clipboard-write"}).then((permission) => {
          if (permission.state === "granted" || permission.state === "prompt") {
            navigator.clipboard.write(data).then(resolve, reject).catch(reject);
          }
          else {
            reject(new Error("Permission not granted!"));
          }
        });
      }
      else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";
        textarea.style.width = '2em';
        textarea.style.height = '2em';
        textarea.style.padding = 0;
        textarea.style.border = 'none';
        textarea.style.outline = 'none';
        textarea.style.boxShadow = 'none';
        textarea.style.background = 'transparent';
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand("copy");
          document.body.removeChild(textarea);
          resolve();
        }
        catch (e) {
          document.body.removeChild(textarea);
          reject(e);
        }
      }
      else {
        reject(new Error("None of copying methods are supported by this browser!"));
      }
    });  
  }

  return (
    <MDBModal 
      open={!!selectedPublication}
      tabIndex="-1" 
      onClick={(e) => {
        if (e.target.classList.contains("modal")) {
          onCloseModal(); // Close modal if clicking on the backdrop
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          onCloseModal(); // Close modal on ESC key press
        }
      }} >
      <MDBModalDialog size="xl">
        <MDBModalContent>
          {/* Modal Header */}

          <MDBModalHeader>
            <div className="d-flex align-items-center w-100">
            <MDBTooltip tag="a" title="Click to copy">
              <MDBBtn
                color="link"
                className="d-flex align-items-center p-0 border-0 fs-6"
                onClick={() => {
                  copy(`${window.location.origin}/publications/${selectedPublication.id}`)
                  // navigator.clipboard.writeText(
                  //   `${window.location.origin}/publications/${selectedPublication.id}`
                  // );
                  setShowAlert(true);
                  setTimeout(() => setShowAlert(false), 1000);
                }}
                >
                  <i className="icon-link me-2"></i> {selectedPublication.id}
                </MDBBtn>
              </MDBTooltip>
            </div>
            {showAlert && (
              <div
                className="alert alert-warning alert-dismissible"
                role="alert"
                style={{
                  position: "fixed",
                  top: "10px",
                  right: "10px",
                  width: "200px",
                  zIndex: 1050,
                }}
              >
                <div style={{
                  width: "190px",
                }}>
                  Copied to clipboard!
                </div>
              </div>
            )}
            <MDBBtn className="btn-close" color="none" onClick={onCloseModal} />
          </MDBModalHeader>
  
          {/* Modal Body */}
          <MDBModalBody>
            <MDBContainer>
              {/* Title */}
              <MDBTypography tag="h4" className="mb-2">
                {selectedPublication.title || "Untitled Publication"}
              </MDBTypography>
    
              {/* Download/Link Buttons */}
              <MDBCardText>
                {[
                  {
                    key: "DOI",
                    label: "DOI",
                    prefix: "https://doi.org/",
                    icon: "fas fa-link",
                  },
                  {
                    key: "paperPdf",
                    label: "Paper PDF",
                    prefix: "/pubs/papers/",
                    icon: "fas fa-file-pdf",
                  },
                  {
                    key: "presentationPdf",
                    label: "Presentation PDF",
                    prefix: "/pubs/presentations/",
                    icon: "fas fa-file-powerpoint",
                  },
                  {
                    key: "posterPdf",
                    label: "Poster PDF",
                    prefix: "/pubs/posters/",
                    icon: "fas fa-file-pdf",
                  },
                ]
                  .filter((link) => selectedPublication[link.key])
                  .map((link, idx) => (
                      <MDBBtn
                        key={idx}
                        tag="a"
                        href={`${link.prefix}${selectedPublication[link.key]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="sm"
                        color="secondary"
                        style={{ width: 'auto' }}
                        className="me-2 mt-2"
                      >
                        {link.label}
                      </MDBBtn>
                  ))}
              </MDBCardText>
    
              {/* Authors */}
              <MDBCardText>
                <strong>Authors:</strong>{" "}
                {selectedPublication.author
                  ?.map((a) => `${a.given || ""} ${a.family || ""}`.trim())
                  .join(", ")}
              </MDBCardText>
    
              {/* Journal */}
              {selectedPublication["container-title"] && (
                <MDBCardText>
                  <strong>Journal:</strong> {selectedPublication["container-title"]}
                </MDBCardText>
              )}

    
              {/* Year */}
              <MDBCardText>
                <strong>Year:</strong>{" "}
                {selectedPublication.issued?.["date-parts"]?.[0]?.[0] || "Unknown"}
              </MDBCardText>
    
              {/* Abstract */}
              <MDBCardText>
                <strong>Abstract:</strong>{" "}
                {selectedPublication.abstract || "No abstract available."}
              </MDBCardText>
    
              {/* Keywords */}
              <MDBCardText>
                <strong>Keywords:</strong>{" "}
                {selectedPublication.keyword
                  ?.split(/[,;]+/)
                  .map((keyword, idx) => (
                    <MDBBadge key={idx} color="info" pill className="ms-1">
                      {keyword.trim()}
                    </MDBBadge>
                  )) || "None"}
              </MDBCardText>

              <MDBCardText>
                <strong>Tags:</strong>{" "}
                {selectedPublication.tags &&
                selectedPublication.tags.map((tag, idx) => (
                  <MDBBadge key={idx} color="primary"  className="ms-1">
                    {tag}
                  </MDBBadge>
                ))}
              </MDBCardText>
    
              {/* YouTube Video Embed */}
              {selectedPublication.youtubeLink && (
                <MDBContainer className="ratio ratio-16x9 my-3">
                  <iframe
                    src={selectedPublication.youtubeLink}
                    title="YouTube video"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  />
                </MDBContainer>
              )}
    
              {/* Canva Presentation Embed */}
              {selectedPublication.canvaPresentation && (
                  <div className="canva-embed-container">
                  <iframe
                    className="canva-embed-iframe"
                    loading="lazy"
                    src={selectedPublication.canvaPresentation}
                    allowFullScreen
                    title="Canva presentation"
                  ></iframe>
                </div>
              )}

              {/* Citation formatting */}
              <MDBCardText className="d-flex align-items-center mb-3">
                <strong className="me-2">Reference style:</strong>
                <select
                  value={selectedStyle}
                  onChange={(e) => onStyleChange(e.target.value)}
                  className="form-select w-auto"
                >
                  {styles.map((style) => (
                    <option key={style} value={style}>
                      {style.toUpperCase()}
                    </option>
                  ))}
                </select>
              </MDBCardText>
    
              {/* Reference */}
              <MDBContainer id="reference">
                 <MDBTooltip tag="a" title="Click to copy">
                  <p
                    color="link"
                    className="d-flex align-items-center p-0 border-0 fs-6"
                    style={{
                      fontWeight: "600",
                      color: "#4a4a4a",
                      margin: 0,
                    }}
                    onClick={(e) => {
                      if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
                        navigator.clipboard.write([
                          new ClipboardItem({
                            "text/html": new Blob([e.currentTarget.innerHTML], { type: "text/html" }),
                            "text/plain": new Blob([e.currentTarget.textContent], { type: "text/plain" }),
                          }),
                        ])
                        .then(() => {
                          setShowAlert(true);
                          setTimeout(() => setShowAlert(false), 1000);
                        })
                        .catch((err) => {
                          copy(e.currentTarget.textContent)
                        });
                      }
                      else{
                        copy(e.currentTarget.textContent);
                        setShowAlert(true);
                        setTimeout(() => setShowAlert(false), 1000);
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: new Cite(selectedPublication).format(selectedStyle === "biblatex" ? "biblatex" : "bibliography", {
                        template: selectedStyle,
                        format: "html"
                      }),
                    }}
                  />
                </MDBTooltip>
              </MDBContainer>
            </MDBContainer>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
