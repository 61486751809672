// FAQ.jsx
import React from 'react';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

const FAQ = ({ title, faqData = [] }) => {
  return (
    <div>
      {title && <h1 className="text-center mb-4">{title}</h1>}

      <MDBAccordion className="mb-4">
        {faqData.map((item, index) => (
          <MDBAccordionItem
            key={index}
            collapseId={`faq-${index}`}
            headerTitle={item.question}
          >
            <div dangerouslySetInnerHTML={{ __html: item.answer }} />
          </MDBAccordionItem>
        ))}
      </MDBAccordion>
    </div>
  );
};

export default FAQ;
