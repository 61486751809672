import { resourcesWithTags } from "./resourcesData";

const isNew = (dateString) => {
  // Parse date string in DD-MM-YYYY format
  const [day, month, year] = dateString.split("-").map(Number);
  const addedDate = new Date(year, month - 1, day); // JavaScript months are 0-indexed

  const now = new Date();
  const diffInDays = (now - addedDate) / (1000 * 60 * 60 * 24); // Difference in days
  return diffInDays <= 7;
};


const normalizeTag = (tag) => {
  const trimmed = tag.trim();
  if (!trimmed) return "";
  const words = trimmed.split(/\s+/);
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// Expand "NEW" if resource < 7 days old
const expandedResources = resourcesWithTags.map((resource) => {
  const normalizedTags = resource.tags.map(normalizeTag);
  if (isNew(resource.dateAdded)) {
    normalizedTags.unshift("NEW");
  }
  return { ...resource, tags: normalizedTags };
});

export function useResources(searchTerm, selectedTags) {
  // Filter by searchTerm (title or reference)
  const filteredBySearch = (() => {
    if (!searchTerm.trim()) return expandedResources;
    const lowerSearch = searchTerm.toLowerCase();

    return expandedResources.filter((res) => {
      const inTitle = res.title.toLowerCase().includes(lowerSearch);
      const inReference = res.reference.toLowerCase().includes(lowerSearch);
      return inTitle || inReference;
    });
  })();

  const filteredByTags = filteredBySearch.filter((res) => {
    if (selectedTags.length === 0) return true;
    return selectedTags.every((tag) => res.tags.includes(tag));
  });

  // Sort by date (asc or desc)
  const sortedResources = (() => {
    const sorted = [...filteredByTags];
    sorted.sort((a, b) => {
      return new Date(a.dateAdded) - new Date(b.dateAdded); // negative means A < B
    });
    return sorted;
  })();

  // Unique tags (including "NEW")
  const uniqueTags = (() => {
    const allTags = new Set();
    expandedResources.forEach((res) => {
      res.tags.forEach((tag) => allTags.add(tag));
    });
    let arrayTags = Array.from(allTags).sort();

    // Move "NEW" to the front if present
    if (arrayTags.includes("NEW")) {
      arrayTags = arrayTags.filter((t) => t !== "NEW");
      arrayTags.unshift("NEW");
    }
    return arrayTags;
  })();

  return {
    resources: sortedResources,
    tags: uniqueTags,
  };
}
