// src/components/publications/PublicationSearchBar.jsx
import React from "react";
import { MDBRow, MDBCol, MDBInput } from "mdb-react-ui-kit";

export default function PublicationSearchBar({ searchTerm, onSearchChange }) {
  return (
    <MDBRow className="mb-4">
      <MDBCol md="6" className="mx-auto">
        <MDBInput
          label="Filter Publications"
          id="searchInput"
          type="text"
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </MDBCol>
    </MDBRow>
  );
}
