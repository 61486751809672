// DynamicSummary.jsx
import React, { useEffect, useState } from 'react';

const DynamicSummary = ({ contentRef }) => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (contentRef.current) {
      // Extract all <h4> elements with IDs inside the content
      const headings = Array.from(contentRef.current.querySelectorAll('h4[id]'));

      // Create the sections array from the headings
      const sectionsData = headings.map((heading) => ({
        id: heading.id,
        title: heading.textContent,
      }));

      setSections(sectionsData);
    }
  }, [contentRef]);

  return (
    <nav>
      <h4>Sumário</h4>
      <ul>
        {sections.map((section) => (
          <li key={section.id}>
            <a href={`#${section.id}`}>{section.title}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default DynamicSummary;
