// src/components/publications/PublicationList.jsx
import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBBadge,
} from "mdb-react-ui-kit";

export default function PublicationList({
  groupedByYear,
  filteredPublications,
  onOpenModal,
}) {
  return (
    <MDBRow className="gy-4">
      {Object.keys(groupedByYear)
        .reverse() // Show most recent year first
        .map((year) => {
          const hasVisiblePublications = filteredPublications[year]?.length > 0;

          return (
            <React.Fragment key={year}>
              {/* Always render the year, but hide if no publications are visible */}
              <MDBCardTitle
                style={{ display: hasVisiblePublications ? "block" : "none" }}
              >
                {year}
              </MDBCardTitle>
              {groupedByYear[year].map((publication, index) => {
                let isVisible = false;

                // Ensure filteredPublications[year] exists and is an array
                if (Array.isArray(filteredPublications[year])) {
                  for (const item of filteredPublications[year]) {
                    // Compare stringified versions
                    if (JSON.stringify(item) === JSON.stringify(publication)) {
                      isVisible = true;
                      break; // Stop the loop once a match is found
                    }
                  }
                }
                return (
                  <MDBCol
                    md="12"
                    key={index}
                    style={{ display: isVisible ? "block" : "none" }}
                  >
                    <MDBCard
                      className="h-100 card-hover"
                      onClick={() => onOpenModal(publication)}
                    >
                      <MDBRow className="g-0">
                        {/* Left-side Image */}
                        <MDBCol
                          md="3"
                          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                          <MDBCardImage
                            src={`/pubs/thumbnail/${publication.thumbnail}`}
                            alt="Publication Cover"
                            className="img-fluid rounded-start"
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </MDBCol>

                        {/* Card Body */}
                        <MDBCol md="9">
                          <MDBCardBody>
                            <MDBCardTitle className="mb-3">
                              {publication.title || "Untitled Publication"}
                            </MDBCardTitle>
                            <MDBCardText>
                              {publication.author
                                ?.map((a) =>
                                  `${a.given || ""} ${a.family || ""}`.trim()
                                )
                                .join(", ")}
                            </MDBCardText>

                            {publication.keyword && (
                              <MDBCardText>
                                {publication.keyword
                                  .split(/[,;]+/)
                                  .map((keyword, idx) => (
                                    <MDBBtn
                                      size="sm"
                                      disabled
                                      outline
                                      color="warning"
                                      key={idx}
                                      className="me-2 mb-2"
                                    >
                                      {keyword.trim().toLowerCase()}
                                    </MDBBtn>
                                  ))}
                              </MDBCardText>
                            )}
                            <MDBCardText>
                              {publication.tags &&
                                publication.tags.map((tag, idx) => (
                                  <MDBBadge key={idx} color="primary" pill className="me-2">
                                    {tag}
                                  </MDBBadge>
                                ))}
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                );
              })}
            </React.Fragment>
          );
        })}
    </MDBRow>
  );
}
