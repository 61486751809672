import React from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardSubTitle, MDBCardText, MDBBadge } from "mdb-react-ui-kit";

/**
 * Displays a single resource as a card.
 * No date is shown here (mobile/small screens).
 */
export default function ResourceCard({ resource, onTagClick }) {
  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        {/* Title */}
        <MDBCardTitle>
          <a href={resource.url} target="_blank" rel="noopener noreferrer">
            {resource.title}
          </a>
        </MDBCardTitle>

        {/* Reference */}
        <MDBCardSubTitle>
          <cite>{resource.reference}</cite>
        </MDBCardSubTitle>

        {/* Tags */}
        <MDBCardText>
          {resource.tags.map((tag, idx) => (
            <MDBBadge
              key={idx}
              onClick={() => onTagClick?.(tag)}
              className="me-1 mb-1"
              color={tag === "NEW" ? "success" : "secondary"}
              style={{ cursor: "pointer" }}
            >
              {tag}
            </MDBBadge>
          ))}
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
}
