export const faqData = [
  {
    question: "Eu desconheço as pesquisas e interesses dos professores do meu curso.",
    answer: 
      "É sua responsabilidade buscar informações sobre os interesses dos professores no curso. Uma forma de fazer isso é acessando os sites dos docentes e verificando suas publicações científicas mais recentes.",
  },
  {
    question: "Como posso descobrir o site de um professor?",
    answer: 
      "Nem todos os professores possuem um site. No entanto, a página do IFRS lista os docentes de cada câmpus e, quando disponível, inclui links para seus sites. Além disso, alguns professores divulgam seus sites na assinatura de e-mails, em materiais didáticos ou nas turmas do Moodle.",
  },
  {
    question: "Não encontrei o site do professor, mas achei publicações dele no Google Acadêmico.",
    answer: 
      "Ótimo! Leia as publicações mais recentes para entender melhor os interesses de pesquisa do professor.",
  },
  {
    question: "Não encontrei o site do professor nem publicações no Google Acadêmico. O que faço?",
    answer: 
      "Envie um e-mail cordial para o professor, perguntando se ele está aceitando orientar alunos no TCC e quais são seus interesses de pesquisa.",
  },
  {
    question: "Eu não sei como ler um artigo científico.",
    answer: 
      "A leitura de artigos científicos é bem diferente da leitura de outros tipos de texto. <a target='_blank' rel='noopener noreferrer' href='https://web.archive.org/web/20241223211348/https://web.stanford.edu/class/ee384m/Handouts/HowtoReadPaper.pdf'>Este guia de duas páginas</a> pode ajudá-lo a aprender como ler artigos científicos.",
  },
  {
    question: "Eu tenho dificuldade em ler artigos escritos em inglês.",
    answer: 
      "Grande parte da literatura avançada em computação está em inglês. Se isso ainda é um obstáculo para você, talvez seja melhor aprimorar seu inglês antes de começar o TCC. Há muitos recursos gratuitos disponíveis online para ajudá-lo, como este: <a target='_blank' rel='noopener noreferrer' href='https://letmegooglethat.com/?q=como+aprender+a+ler+em+ingl%C3%AAs+de+gra%C3%A7a'>Aprender inglês gratuitamente</a>.",
  },
  {
    question: "Li o artigo, mas não entendi quase nada. O que faço?",
    answer: 
      "Leia novamente com atenção e procure entender os termos desconhecidos no Google ou em ferramentas como o ChatGPT. Artigos científicos frequentemente abordam áreas muito específicas e avançadas. Seu curso deve ter lhe dado uma base suficiente para entender, ao menos, a área do artigo e o problema que ele tenta resolver. Conseguir interpretar artigos científicos é essencial para conduzir um TCC.",
  },
  {
    question: "Entendi o artigo, mas não tem relação com os meus interesses.",
    answer: 
      "Isso é perfeitamente normal. É provável que o professor prefira orientar alunos com interesses alinhados aos dele. Lembre-se: <strong>você precisa do orientador, não o contrário</strong>. Da mesma forma que você não deve trabalhar em algo que não lhe interessa, o professor não é obrigado a orientar temas que não são de interesse dele.",
  },
  {
    question: "Eu gosto muito deste professor. Ele aceitaria me orientar mesmo sem interesses alinhados?",
    answer: 
      "Isso depende do professor. Entre em contato de forma educada e resumida, explicando sua situação e sugerindo próximos passos. Nesta página, você encontrará exemplos de como escrever esse e-mail.",
  },
  {
    question: "Gostei muito de um artigo do professor. Tem tudo a ver com o que quero fazer no meu TCC.",
    answer: 
      "Ótimo! <a href='/students/ifrs#etapa-2a'>Clique aqui</a> para ver como enviar um e-mail adequado para o professor.",
  },
  {
    question: "Gostei de um artigo do professor, mas ainda não sei o que quero fazer no meu TCC.",
    answer: 
      "Sem problemas! <a href='/students/ifrs#etapa-2b'>Clique aqui</a> para aprender como entrar em contato com ele.",
  },
];
