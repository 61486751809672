// src/components/publications/Publications.jsx
import React, { useState, useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { plugins } from "@citation-js/core";

import ieee from "@citation/csl-style-ieee";
import { default as abnt } from "@citation/csl-style-associacao-brasileira-de-normas-tecnicas-ufrgs-note-initials-with-ibid";
import { default as sbc } from "@citation/csl-style-sociedade-brasileira-de-computacao";

// MDB
import { MDBContainer } from "mdb-react-ui-kit";

// Custom Hooks
import groupedByYear from "./useBibtexData.js";
import useSearchFilter from "./useSearchFilter.js";

// // Components
import PublicationSearchBar from "./PublicationSearchBar";
import PublicationList from "./PublicationList";
import PublicationModal from "./PublicationModal";

require("@citation-js/plugin-csl"); // Import CSL plugin
require("@citation-js/plugin-bibtex");

/**
 * Main Publications Page
 */
export default function Publications() {
  // -------------------- Citation Styles Setup --------------------
  useMemo(() => {
    const bibtexConfig = plugins.config.get('@bibtex')
    bibtexConfig.format.asciiOnly = false
    // bibtexConfig.parse.sentenceCase = 'always'
        
    // Add custom styles once
    const config = plugins.config.get("@csl");  

    abnt.short_id = "ABNT";
    sbc.short_id = "SBC";

    config.templates.add(ieee.short_id, ieee.content);
    config.templates.add(abnt.short_id, abnt.content.replaceAll("pt-BR", "en-US"));
    config.templates.add(sbc.short_id, sbc.content);
  }, []);

  // -------------------- State --------------------
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStyle, setSelectedStyle] = useState("ABNT");
  const [selectedPublication, setSelectedPublication] = useState(null);

  // URL parameters
  const { id } = useParams();
  const navigate = useNavigate();

  // -------------------- Data --------------------
  // Custom hook to fetch .bib data, create citeInstance, group by year
  // const { groupedByYear } = useBibTexData();

  // Filter publications by the search term
  const filteredPublications = useSearchFilter(groupedByYear, searchTerm);

  // -------------------- Behavior: Opening/Closing Modal --------------------
  const handleOpenModal = (publication) => {
    setSelectedPublication(publication);
    navigate(`/publications/${publication.id}`); // update the URL with the ID
    document.documentElement.style.overflow = "hidden"; // Disable page scroll
  };

  const handleCloseModal = () => {
    setSelectedPublication(null);
    navigate("/publications"); // remove the ID from the URL
    document.documentElement.style.overflow = ""; // Re-enable page scroll
  };

  // If the user loads /publications/:id directly, set the modal
  useEffect(() => {
      // -------------------- Sync with URL Parameter --------------------
      // Helper to find a publication by ID in groupedByYear
      const findPublicationById = (pubId) => {
        for (const year in groupedByYear) {
          const publication = groupedByYear[year].find((pub) => pub.id === pubId);
          if (publication) return publication;
        }
        return null;
      };

    if (!id || !groupedByYear || !Object.keys(groupedByYear).length) {
      // if there's no ID or no data yet, ensure the modal is closed
      setSelectedPublication(null);
      document.documentElement.style.overflow = ""; // Re-enable page scroll
      return;
    }

    // Attempt to find the publication
    const publication = findPublicationById(id);
    if (publication) {
      setSelectedPublication(publication);
      document.documentElement.style.overflow = "hidden"; // Disable page scroll
    } else {
      console.error("Publication not found for ID:", id);
      // Optionally, you could navigate("/publications") to remove the invalid ID
    }
  }, [id]);

  // List of styles for the style <select />
  const styles = [abnt.short_id, sbc.short_id, ieee.short_id, "apa", "vancouver", "harvard1", "biblatex"];

  return (
    <MDBContainer>
      <h1 className="my-3 text-center">Publications</h1>

      {/* Search Bar */}
      <PublicationSearchBar
        searchTerm={searchTerm}
        onSearchChange={(newTerm) => setSearchTerm(newTerm)}
      />

      {/* Publications List */}
      <PublicationList
        groupedByYear={groupedByYear}
        filteredPublications={filteredPublications}
        onOpenModal={handleOpenModal}
      />

      {/* Detailed Modal */}
      <PublicationModal
        selectedPublication={selectedPublication}
        selectedStyle={selectedStyle}
        styles={styles}
        onCloseModal={handleCloseModal}
        onStyleChange={setSelectedStyle}
      />
    </MDBContainer>
  );
}
