import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from 'mdb-react-ui-kit';

export default function Index() {
  const data = {
    Master: [
      {
        name: 'Brendon Vicente Rocha Silva',
        lattes: 'http://lattes.cnpq.br/0013071300709423',
      },
      {
        name: 'Luciano Figueiredo Coelho',
        lattes: 'http://lattes.cnpq.br/1315247519891460',
        mainSupervisor: 'Prof. Ricardo Custódio',
      },
      {
        name: 'Carla Knust Bastos',
        lattes: 'http://lattes.cnpq.br/8692117812392115',
        mainSupervisor: 'Prof. Ricardo Custódio',
      },
      {
        name: 'Chiarelli Bezerra Albuquerque de Araújo Vale',
        lattes: 'http://lattes.cnpq.br/2302334140616142',
        mainSupervisor: 'Prof. Ricardo Custódio',
      },
    ],
    Undergraduate: [
      {
        name: 'Victor dos Santos Camargo',
        lattes: 'http://lattes.cnpq.br/3121495926934301',
      },
      {
        name: 'Eduardo Dani Perottoni',
        lattes: 'http://lattes.cnpq.br/1991768906356828',
      },
      {
        name: 'Victor do Valle Cunha',
        lattes: 'http://lattes.cnpq.br/2121674699282175',
      },
      {
        name: 'Brendon Vicente Rocha Silva',
        lattes: 'http://lattes.cnpq.br/0013071300709423',
      },
      {
        name: 'Maurício de Vasconcelos Barros',
        lattes: 'http://lattes.cnpq.br/8273888309163028',
      },
    ],
  };

  return (
    <section>
      {Object.entries(data).map(([title, students]) => (
        <MDBCard className="mb-4" key={title}>
          <MDBCardBody>
            <MDBCardTitle>{title}</MDBCardTitle>
            <ul className="mb-0">
              {students.map(({ name, lattes, mainSupervisor }, idx) => (
                <li key={idx}>
                  <a href={lattes} target="_blank" rel="noopener noreferrer">
                    {name}
                  </a>
                  {mainSupervisor && (
                    <>
                      <br />
                      Main supervisor: {mainSupervisor}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </MDBCardBody>
        </MDBCard>
      ))}
      <MDBCard className="mb-4">
        <MDBCardBody>
          <MDBCardTitle>Prospective Students</MDBCardTitle>
          If you are an undergraduate student at IFRS, <a href="/students/ifrs">click here</a>. For graduate or other opportunities, please <a href='mailto:mail@frederico.phd' target="_blank" rel="noopener noreferrer" title="mail me">email me</a>.
        </MDBCardBody>
      </MDBCard>
    </section>
  );
}
