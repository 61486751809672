import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBTooltip,
} from 'mdb-react-ui-kit';

const awards = [
  {
    title: 'Inova UFSC Award',
    externalLinks: [
      {
        href: 'https://web.archive.org/web/20240423222922/https://noticias.ufsc.br/2024/04/premio-inova-ufsc-2024-esta-com-inscricoes-abertas/',
        tooltip: 'About'
      },
      {
        href: 'https://web.archive.org/web/20241205084853/https://noticias.ufsc.br/2024/11/premio-inova-ufsc-celebra-trabalhos-e-agentes-de-inovacao-da-universidade/',
        tooltip: 'Recipients announced'
      }
    ],
    description:
      '"The initiative sought to value the emergence of innovative projects and solutions in the areas of teaching, research, extension and university administration, in order to strengthen the culture of innovation in academia and to foster the levels of development of skills linked to the generation, sharing and application of new ideas and knowledge."'
  },
  {
    title: 'Vittorio Bertocci Award',
    externalLinks: [
      {
        href: 'https://web.archive.org/web/20240527224621/https://digitalidadvancement.org/awards-and-grants/vittorio/',
        tooltip: 'About'
      },
      {
        href: 'https://web.archive.org/web/20240821003415/https://digitalidadvancement.org/news/celebrating-excellence-meet-the-first-vittorio-bertocci-award-winners/',
        tooltip: 'Recipients announced'
      },
      {
        href: 'https://web.archive.org/web/20250108205217/https://digitalidadvancement.org/news/2024-vittorio-bertocci-awardee-reflections-frederico-schardong/',
        tooltip: 'ER 2024 experience'
      }
    ],
    description:
      '"The mission of the Vittorio Bertocci Award is to honor Vittorio Bertocci\'s legacy by inspiring and supporting the next generation of identity experts who will shape the foundation of digital identity."'
  },
  {
    title: 'Kim Cameron Award',
    externalLinks: [
      {
        href: 'https://web.archive.org/web/20240907200718/https://openid.net/announcing-the-2022-openid-foundation-kim-cameron-scholarship/',
        tooltip: 'About'
      },
      {
        href: 'https://web.archive.org/web/20240724064435/https://openid.net/2022-openid-foundation-kim-cameron-award-recipients-announced/',
        tooltip: 'Recipients announced'
      },
      {
        href: 'https://web.archive.org/web/20240907202203/https://www.openid.net/frederico-schardongs-experience-as-a-kim-cameron-award-recipient/',
        tooltip: 'Identiverse 2022 Experience'
      }
    ],
    description:
      '"The OpenID Foundation Kim Cameron Award is a recognition of the importance of mentoring a new generation of domain experts and thought leaders in user centric identity. The Foundation is interested in increasing representation from those who\'ve demonstrated an academic and practical interest in subjects consistent with OpenID Foundation\'s Mission."'
  }
];

export default function Awards() {
  return (
    <MDBCard id="awards" className="mb-4">
      <MDBCardBody>
        <MDBCardTitle>Awards</MDBCardTitle>
        {awards.map((award, index) => (
          <React.Fragment key={index}>
            <div className="card-subtitle h6 mb-1">
              <p style={{display: "inline", marginRight: "3px"}}>{award.title}</p>
              {award.externalLinks.map((link, linkIndex) => (
                <MDBTooltip
                  key={linkIndex}
                  className="pb-1"
                  tag="a"
                  wrapperProps={{
                    className: '', 
                    href: link.href,
                    target:"_blank",
                    rel:"noopener noreferrer"
                  }}
                  title={link.tooltip}
                >
                  <i className='icon-link'></i>
                </MDBTooltip>
              ))}
            </div>
            <MDBCardText>{award.description}</MDBCardText>
            {index < awards.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </MDBCardBody>
    </MDBCard>
  );
}
