/**
 * Formats a date string into a locale-aware format, supporting only:
 * 1) DD-MM-YYYY (e.g., "15-01-2023")
 * 2) MM-YYYY    (e.g., "01-2023")
 *
 * If the string doesn't match these formats, returns it unchanged.
 * If parsing fails, also returns the original string.
 *
 * @param {string} dateString - The date string in one of the supported formats.
 * @returns {string} Locale-formatted date or the original string if invalid.
 */
export default function formatDate(dateString) {
    if (!dateString) return "";
  
    const parts = dateString.split("-");
    
    // Case 1: 3 parts => DD-MM-YYYY
    if (parts.length === 3) {
      const [ddStr, mmStr, yyyyStr] = parts;
      const day = parseInt(ddStr, 10);
      const month = parseInt(mmStr, 10) - 1; // zero-based
      const year = parseInt(yyyyStr, 10);
  
      const dateObj = new Date(year, month, day);
      if (isNaN(dateObj.getTime())) {
        return dateString; // Parsing failed
      }
      
      return dateObj;
    }
  
    // Case 2: 2 parts => MM-YYYY
    if (parts.length === 2) {
      const [mmStr, yyyyStr] = parts;
      const month = parseInt(mmStr, 10) - 1; // zero-based
      const year = parseInt(yyyyStr, 10);
  
      // Default day = 1
      const dateObj = new Date(year, month, 1);

      if (isNaN(dateObj.getTime())) {
        return dateString; // Parsing failed
      }

      return dateObj;
    }
  
    // If neither condition applies, just return the original string
    return dateString;
  }
  